import { func } from 'prop-types';

const moment = require('moment');

export function DATE_FORMAT(inputDate) {
  const formattedDate = moment(inputDate).format('MMM D, YYYY');
  return formattedDate
}

export function FORMAT_TEXT(text) {
  const text_ = text?.length > 20 ? text?.substring(0, 20) + '...' : text
  return text_
}

export function FORMAT_TEXT_Temp(text) {
  const text_ = text?.length > 40 ? text?.substring(0, 40) + '...' : text
  return text_
}


export function convertTimestampToDate(timestamp, days) {
  const originalDate = new Date(timestamp);
  const newDate = new Date(originalDate);
  newDate.setDate(newDate.getDate() + days);
  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const day = newDate.getDate().toString().padStart(2, '0');
  return `${day}-${month}-${year}`;
}

export function formatTimestamp(timestamp, timeZone) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timeZone, // Specify the desired time zone here
  };
  return date.toLocaleString('en-US', options);
}

export function IS_BASE64(input) {
  try {
    const base64Data = input.split(',')[1];
    return btoa(atob(base64Data)) === base64Data;
  } catch (e) {
    return false;
  }
}

export function EXPORT_FORMAT_DATA_USER(practitionerList) {
  try {
    let array = []
    for (let i in practitionerList) {
      //profile status
      let profileStatus = practitionerList[i]?.status === 'active' ? 'Active' : 'Inactive'
      // membership status
      let MembershipType = (practitionerList[i]?.nta_status === "approve" && practitionerList[i]?.nta_membership_type === 'renewable') ?
        "Current Member" : (practitionerList[i]?.nta_status === 'pending' ? 'Unverified' : practitionerList[i]?.nta_status === "reject" ? 'Non Member' : (practitionerList[i]?.nta_status === "approve" && practitionerList[i]?.nta_membership_type === 'non_renewable') ? 'Cancelling Member' : practitionerList[i]?.is_free_account === "yes" ? 'Student' : 'Non Member')
      //plan status
      let planStatus = practitionerList[i].plan_status === 'active' ? 'Active' : practitionerList[i]?.plan_status === 'expired' ? 'Expired' : practitionerList[i]?.is_free_account === 'yes' ? 'Trial' : 'Trial';
      //olan expire status
      let PlanExpiredStatus = practitionerList[i]?.is_free_account === 'yes' ? DATE_FORMAT(practitionerList[i]?.free_account_expiry) : practitionerList[i]?.current_period_end ? TIMESTAMP_FORMAT_TODATE(practitionerList[i]?.current_period_end) : ''
      //nta email
      let contactEmail;
      if (practitionerList[i]?.nta_data !== 'null') {
        const contactData = practitionerList[i]?.nta_data;
        if (contactData) {
          try {
            contactEmail = JSON.parse(contactData);
          } catch (err) {
            console.error("Error parsing contact data:", err);
          }
        }
      }
      if (practitionerList[i]?.uuid !== null && practitionerList[i]?.uuid !== 'null') {
        const final_obj = {
          "uuid" : practitionerList[i]?.uuid,
          "first_name" : practitionerList[i]?.first_name ? practitionerList[i]?.first_name : '',
          "last_name" : practitionerList[i]?.last_name ? practitionerList[i]?.last_name : '',
          "display_name" : practitionerList[i]?.display_name ? practitionerList[i]?.display_name : '',
          "account_email" : practitionerList[i]?.email ? practitionerList[i]?.email : '',
          "nta_contact_email": contactEmail ? contactEmail.email : '', 
          "phone": practitionerList[i]?.phone ? `${practitionerList[i]?.country_code}${practitionerList[i]?.phone}` : '',
          "profile_status" : profileStatus,
          "package_name" : practitionerList[i]?.package_name ? practitionerList[i]?.package_name : '',
          "plan_status" : planStatus,
          "plan_expiration_date" : PlanExpiredStatus,
          "membership_type" : MembershipType,
          "membership_id" :practitionerList[i]?.nta_membership_id ?  practitionerList[i]?.nta_membership_id : '',
          "membership_expiration_date" : practitionerList[i]?.nta_membership_expiry ? TIMESTAMP_FORMAT_TODATE(practitionerList[i]?.nta_membership_expiry) : null,
          "created_at" : practitionerList[i]?.created_at? DATE_FORMAT(practitionerList[i]?.created_at) : '',
          "updated_at" : practitionerList[i]?.updated_at ? DATE_FORMAT(practitionerList[i]?.updated_at) : '',
        }
        array.push(final_obj)
      }
    }
    return array
  } catch (e) {
    return false;
  }
}

export function TIMESTAMP_FORMAT_TODATE(timestamp) {
  const date = moment(timestamp);
  const formattedDate = date.format('MMMM DD, YYYY');
  return formattedDate
}

export function DUMMY_SAMPLET_STUDENET_PRAC(type, practitionerList){
  try{
    let arry = []
    let finalObj= {}
    if(type==='student'){
      finalObj = {
        'first_name' : "Jhon",
        'last_name' : "Jhon",
        'email' : "jhon@gmail.com",
        'phone' : "",
        'country_code' : "",
        'is_free_account' : "yes",
        'free_account_expiry' : "2023-11-06",
        "status":"active"
      } 
    }
    else if(type==='practitioner_update'){
     let res = IMPORT_EXPORT_FORMAT_DATA_USER(practitionerList);
     return res
    }
    else{
      finalObj = {
        'first_name' : "Rohan",
        'last_name' : "Rohan",
        'email' : "rohan@gmail.com",
        'phone' : "",
        'country_code' : "",
        "status":"active"
      } 
    }
    arry.push(finalObj)
    return arry
    } catch (e) {
      return false;
    }
}
export function IMPORT_EXPORT_FORMAT_DATA_USER(practitionerList) {
  try {
    let array = []
    for (let i in practitionerList) {
      //nta email
      let contactEmail;
      if (practitionerList[i]?.nta_data !== 'null') {
        const contactData = practitionerList[i]?.nta_data;
        if (contactData) {
          try {
            contactEmail = JSON.parse(contactData);
          } catch (err) {
            console.error("Error parsing contact data:", err);
          }
        }
      }
      if (practitionerList[i]?.uuid !== null && practitionerList[i]?.uuid !== 'null') {
        const final_obj = {
            "uuid" : practitionerList[i]?.uuid,
            "first_name" : practitionerList[i]?.first_name ? practitionerList[i]?.first_name : '',
            "last_name" : practitionerList[i]?.last_name ? practitionerList[i]?.last_name : '',
            "display_name" : practitionerList[i]?.display_name ? practitionerList[i]?.display_name : '',
            "account_email" : practitionerList[i]?.email ? practitionerList[i]?.email : '',
            // "nta_contact_email": contactEmail ? contactEmail.email : '', 
            "profile_status" :  practitionerList[i]?.status,
            "membership_id" :practitionerList[i]?.nta_membership_id ?  practitionerList[i]?.nta_membership_id : '',
            "membership_expiration_date" : practitionerList[i]?.nta_membership_expiry ? TIMESTAMP_FORMAT_TODATE(practitionerList[i]?.nta_membership_expiry) : null,
            "updated_at" : practitionerList[i]?.updated_at ? DATE_FORMAT(practitionerList[i]?.updated_at) : '',
        }
        array.push(final_obj)
      }
    }
    return array
  } catch (e) {
    return false;
  }
}


export const validateInputes = async (validSchema, data) => {
  try {
    await validSchema.validate(data, { abortEarly: false });
    return { isValid: true, errors: {} };
  } catch (error) {
    const errors = {};
    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    return { isValid: false, errors };
  }
};

export function UC_FIRST(string) {
  if(!string) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatText(text) {
  text = text.replaceAll("_", "-");
  return UC_FIRST(text);
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export const urlToBase64 = url => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
          resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
});

export function getMimeType(fileExtension) {
  const mimeTypes = {
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'pdf': 'application/pdf',
      // Add more MIME types as needed
  };

  // Convert the file extension to lowercase to ensure case insensitivity
  const extension = fileExtension.toLowerCase();

  // Look up the MIME type in the mapping
  const mimeType = mimeTypes[extension];

  // If MIME type is found, return it, otherwise return a default value
  return mimeType || 'application/octet-stream'; // Default MIME type
}

export function createSlug(text, maxLength = 50) {
  // Step 1: Convert text to lowercase
  let slug = text.toLowerCase()
      .trim() // Remove leading and trailing spaces
      .replace(/[^\w\s-]/g, '') // Remove all non-word characters (except spaces and hyphens)
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen

  // Step 2: Truncate slug to maxLength
  if (maxLength && slug.length > maxLength) {
      slug = slug.slice(0, maxLength);
      
      // Ensure we don't end with a partial word by trimming to the last complete word
      if (slug.charAt(slug.length - 1) === '-') {
          slug = slug.slice(0, -1);
      }
  }
  
  return slug;
}

// check if provided value is falsy
export function isFalsy(value) {
  return !value || value === "null";
}
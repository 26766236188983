import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "components/shared/Dropzone";
// import CKEditorField from "./CKEditorField";
import Select from "react-select";

//imported
import { PRACTITIONER, PAGE_LIMIT } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import {
  viewPractitionerData,
  getPractitionerHistoryList,
  getPractitionerMyClientList,
  getPractitionerNotesList,
  addPractitionerNotes,
  resendPasswordResetLink,
  resetUserPassword,
  loadSampleDataIntoUser,
} from "store/services/practitionerService";
import InputFields from "./inputFields";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactPagination from "components/shared/ReactPagination";
import MemberShipPop from "./Modal/MemberShipPop";
import {
  DATE_FORMAT,
  FORMAT_TEXT,
  convertTimestampToDate,
  formatTimestamp,
} from "helpers/common";
import { postData } from "helpers/apiHelper";
import EditUser from "./Modal/EditUser";
import moment from "moment";
import { CONFIRM_ALERT, SUCCESS_MESSAGE } from "common/constantMessage";
import PasswordUpdatedPopup from "components/shared/Modals/PasswordUpdatedPopup";

const View = ({
  submitting,
  dispatch,
  match,
  practitionerNotes,
  pagination,
  myClintData,
  practitionerHistory,
  practitionerDetail,
  loader,
}) => {
  const clientsTableRef = useRef();
  const formRef = useRef();
  practitionerNotes.practitionerNotes = practitionerNotes.practitionerNotes.length > 0 && practitionerNotes?.practitionerNotes?.map((data) => {
    try {
      data = {
        ...data,
        notes_data: data.notes_data ? JSON.parse(data.notes_data) : null,
      };
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    return data;
  });
  practitionerHistory = practitionerHistory.length > 0 && practitionerHistory?.map((data) => {
    try {
      data = {
        ...data,
        purchase_response: data.purchase_response
          ? JSON.parse(data.purchase_response)
          : null,
        purchased_package: data.purchased_package
          ? JSON.parse(data.purchased_package)
          : null,
      };
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    return data;
  });
  const {
    itemsCountPerPage,
    totalItemsCount,
    itemsCountPerPageClint,
    totalItemsCountClint,
  } = pagination;
  const [values, setFieldValue] = useState();
  const [checkData, setCheckData] = useState(false);
  const [checkNotes, setCheckNotes] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [clintActivePage, setClintActivePage] = useState(1);

  // password updated popup
  const [showPwdUpdatedPopup, setShowPwdUpdatedPopup] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(null);

  const history = useHistory();

  let editkey = match.params.id;
  let PathUrl;
  let loading;

  const navigateToAnotherPage = (selectedValue) => {
    history.push({
      pathname: PathUrl,
      state: { paramValue: selectedValue },
    });
  };
  const viewData = {
    first_name: practitionerDetail ? practitionerDetail.first_name : "",
    last_name: practitionerDetail ? practitionerDetail.last_name : "",
    email: practitionerDetail ? practitionerDetail.email : "",
    gender: practitionerDetail ? practitionerDetail.gender : "",
    phone: practitionerDetail?.phone ? `${practitionerDetail?.country_code}-${practitionerDetail?.phone}` : (practitionerDetail?.phone || "--"),
    country_code: practitionerDetail ? practitionerDetail?.country_code : "",
    is_free_account: practitionerDetail ? practitionerDetail.is_free_account : "",
    free_account_expiry: practitionerDetail ? practitionerDetail.free_account_expiry : "",
    status: practitionerDetail ? practitionerDetail.status : "",
    profile_image: practitionerDetail ? practitionerDetail.profile_image : "",
    password: "*******",
  };

  //getHistoryData
  async function getHistoryData() {
    try {
      let query = {
        page: activePage,
        limit: PAGE_LIMIT,
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPractitionerHistoryList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //getHistoryData
  async function getMyClientData() {
    try {
      let query = {
        page: clintActivePage,
        limit: PAGE_LIMIT,
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPractitionerMyClientList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //get detail
  async function getApiData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewPractitionerData(editkey));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  // get notes data
  async function getNotesData() {
    try {
      let query = {
        page: clintActivePage,
        limit: PAGE_LIMIT,
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPractitionerNotesList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    getMyClientData();
  }, [clintActivePage])

  useEffect(() => {
    getHistoryData();
    // getMyClientData();
    getApiData();
    getNotesData();
  }, []);

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  const handleClintPageChange = (page) => {
    setClintActivePage(page);
    if(clientsTableRef?.current) {
      clientsTableRef?.current.scrollIntoView();
    }
    // window.scrollTo(0, 0);
  };

  const [showMembership, setShowMembership] = useState(false);
  const handleMembership = () => setShowMembership(!showMembership);
  const [showEditUser, setShowEditUser] = useState(false);
  const handleEditUser = () => setShowEditUser(!showEditUser);
  const handleResetLink = () => alert("i")

  const changeStatue = async (data) => {
    console.log(data, "--d")
    let stateData = {
      status: data,
    };
    let url = `/admin/practitioner/membership/status/${editkey}`;
    await postData(url, stateData);
    window.location.reload()
  };

  const handleNotes = (e) => {
    const { name, value } = e.target;
    setFieldValue({ ...values, [name]: value });
    setCheckNotes(true);
  };

  const saveNotes = async () => {
    let data;
    if (checkData) {
      data = "contact_notes";
    } else if (checkNotes) {
      data = "add_notes";
    }
    values.notes_type = data;
    values.user_id = editkey;
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(addPractitionerNotes(values));
      dispatch(actions.persist_store({ loader: false }));
      formRef.current.reset();
      setFieldValue(false);
      setCheckNotes(false)
      setCheckData(false)
      getHistoryData();
      getMyClientData();
      getApiData();
      getNotesData();
    } catch (error) {
      console.log(error);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const resendPwdResetLink = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let reponse = await resendPasswordResetLink(editkey);
      // if (reponse?.success === true) {
      //   SUCCESS_MESSAGE("Password reset link sent successfully")
      // }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
    }
    // try {
    //   await DELETE_ALERT().then(async (willSend) => {
    //     if (willSend) {
    //       try {
    //         dispatch(actions.persist_store({ loader: true }));
    //         await dispatch(resendPasswordResetLink(editkey));
    //         dispatch(actions.persist_store({ loader: false }));
    //       } catch (err) {
    //         dispatch(actions.persist_store({ loader: false }));
    //       }
    //     }
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  }

  const resetPassword = async () => {
    try {
      await CONFIRM_ALERT("Are you sure you want to reset user password?").then(async (confirmed) => {
        if (confirmed) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            let response = await resetUserPassword(editkey);
            if (response?.success === true) {
              setUpdatedPassword(response?.body?.updated_password);
              setShowPwdUpdatedPopup(true);
            }
            dispatch(actions.persist_store({ loader: false }));
          } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  const loadSampleData = async () => {
    try {
      await CONFIRM_ALERT().then(async (confirmed) => {
        if (confirmed) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            await loadSampleDataIntoUser(editkey);
            await getMyClientData();
            await getApiData();
            dispatch(actions.persist_store({ loader: false }));
          } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <MemberShipPop
        showMembership={showMembership}
        setShowMembership={setShowMembership}
        editkey={editkey}
        data={practitionerDetail}
      />

      <EditUser
        showEditUser={showEditUser}
        setShowEditUser={setShowEditUser}
        editkey={editkey}
        practitionerDetail={practitionerDetail}
      />

      <PasswordUpdatedPopup
        showPopup={showPwdUpdatedPopup}
        setShowPopup={setShowPwdUpdatedPopup}
        updatedPassword={updatedPassword}
      />

      <Helmet title={PRACTITIONER.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{PRACTITIONER.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">

            <div className="breadcrumb-item">
              <Link to={Path.practitioner}>{PRACTITIONER.CURRENT_MODULE}</Link>
            </div>
            <div className="breadcrumb-item">View Practitioner</div>
          </div>
        </div>
        {/* {!props.loader && */}
        <div className="section-body">
          <h2 className="section-title">View Practitioner</h2>
          <p className="section-lead">
            On this page you see practitioner detail.
          </p>
          <div className="row mt-sm-4 align-items-start">
            <div className="col-4 my-2 sticky-top">
              <div className="py-2">
                <div className="bg-white card-box pb-3 rounded h-auto">
                  <div className="cover-profile-wrp position-relative ">
                    <div
                      className="cover-profile position-relative text-right h-auto rounded"
                      style={{ background: "#34c38f40" }}
                    >
                      <img
                        //   src="/assets/images/cover-vector.png"
                        src="/assets/images/cover-vector.png"
                        alt=""
                        className="img-fluid"
                      ></img>
                    </div>

                    <div className="px-3 d-flex align-items-center justify-content-center profile-view">
                      {/* <div className="img-wrp mr-2 rounded-circle p-1">
                        <img
                          src={"/assets/images/dummy.png"}
                          alt=""
                          className="img-fluid rounded-circle w-100 h-100"
                        />
                      </div> */}
                      <div className="content">
                        <h6 className="m-0 fw-sbold">
                          {InputFields?.map((field, idx) =>
                            field.name == "first_name" ? (
                              <>{field.name ? viewData?.first_name : "--"}</>
                            ) : (
                              ""
                            )
                          )}{" "}
                          {InputFields?.map((field, idx) =>
                            field.name == "last_name" ? (
                              <>{field.name ? viewData?.last_name : "--"}</>
                            ) : (
                              ""
                            )
                          )}
                        </h6>
                      </div>
                    </div>
                    <ul className="list-unstyled px-3 mb-0 d-flex align-items-center justify-content-between py-2 profile-featured">
                      <li className="px-2 w-50">
                        {/* <h4 className="m-0 pb-1"> 28-03-2023</h4> */}
                        <p className="text-muted m-0 py-1">Created At</p>
                        <h4 className="m-0 pb-1">
                          {DATE_FORMAT(practitionerDetail?.created_at)}
                        </h4>
                      </li>
                      <li className="px-2 text-center w-50">
                        <p className="text-muted m-0 py-1">Status</p>

                        <h4 className="m-0 pb-1">
                          {practitionerDetail?.nta_status === "approve" &&
                            practitionerDetail?.nta_membership_type === "renewable"
                            ? "Current Member"
                            : practitionerDetail?.nta_status === "pending"
                              ? "Unverified"
                              : practitionerDetail?.nta_status === "reject"
                                ? "Non Member"
                                : practitionerDetail?.nta_status === "approve" &&
                                  practitionerDetail?.nta_membership_type ===
                                  "non_renewable"
                                  ? "Cancelling Member"
                                  : practitionerDetail?.is_free_account === 'yes' ? 'Student'
                                    : "Non Member"}
                        </h4>

                      </li>
                    </ul>
                    {/* <ul className="list-unstyled px-3 mb-0 d-flex align-items-center justify-content-between py-2 profile-featured">
                      <li className="px-2 w-50">
                        <p className="text-muted m-0 py-1">
                          Membership Expiration Date
                        </p>
                        <h4 className="m-0 pb-1">
                          {practitionerDetail?.nta_membership_expiry
                            ? DATE_FORMAT(
                              practitionerDetail?.nta_membership_expiry
                            )
                            : "N/A"}
                        </h4>
                      </li>
                      <li className="px-2 text-center w-50">
                        <p className="text-muted m-0 py-1">NTA Member ID</p>
                        <h4 className="m-0 pb-1">
                          {practitionerDetail?.nta_membership_id
                            ? FORMAT_TEXT(practitionerDetail?.nta_membership_id)
                            : "N/A"}
                        </h4>
                      </li>
                    </ul> */}
                    <ul className="list-unstyled px-3 mb-0 d-flex align-items-center justify-content-between py-2 profile-featured">
                      <li className="px-2 w-33">
                        {/* <h4 className="m-0 pb-1"> 28-03-2023</h4> */}
                        <p className="text-muted m-0 py-1">Storage Used</p>
                        <h4 className="m-0 pb-1">0.1GB </h4>
                      </li>
                      <li className="px-2 text-center w-33">
                        {/* <h4 className="m-0 pb-1">
                          {" "}
                          {practitionerDetail?.status}
                        </h4> */}
                        <p className="text-muted m-0 py-1">
                          Current Subscription Plan
                        </p>
                        <h4 className="m-0 pb-1">
                          {practitionerDetail?.package_name
                            ? FORMAT_TEXT(practitionerDetail?.package_name)
                            : "N/A"}
                        </h4>
                      </li>
                      <li className="px-2 text-center w-33">
                        {/* <h4 className="m-0 pb-1">
                          {" "}
                          {practitionerDetail?.status}
                        </h4> */}
                        <p className="text-muted m-0 py-1">
                          Plan Expiration Date
                        </p>
                        <h4 className="m-0 pb-1">
                          {/* {DATE_FORMAT(practitionerHistory[0]?.created_at)} */}
                          {practitionerDetail?.current_period_end
                            ? convertTimestampToDate(
                              practitionerDetail?.current_period_end,
                              30
                            )
                            : "N/A"}
                        </h4>
                      </li>
                    </ul>
                    {/* <div className="py-2 px-3">
                      {practitionerDetail?.nta_status === "approve" &&
                        practitionerDetail.nta_membership_type === "renewable" ? (
                        <div className="btnWrp my-2 d-flex align-items-center justify-content-center gap-10 flex-wrap">
                          <div className="d-flex align-items-center gap-10 w-100">
                            <Button
                              onClick={handleMembership}
                              className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                            >
                              Update NTA Membership
                            </Button>
                          </div>
                          <Button
                            onClick={() => changeStatue("reject")}
                            className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                          >
                            Non Renewing NTA Membership
                          </Button>
                        </div>
                      ) : practitionerDetail?.nta_status === "pending" ? (
                        <div className="btnWrp my-2 d-flex align-items-center justify-content-center gap-10 flex-wrap">
                          <div className="d-flex align-items-center gap-10 w-100">
                            <Button
                              onClick={() => changeStatue("approve")}
                              className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                            >
                              Aprove
                            </Button>
                          </div>
                          <Button
                            onClick={() => changeStatue("reject")}
                            className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                          >
                            Reject
                          </Button>
                        </div>
                      ) : (practitionerDetail?.nta_status === "reject") ? (
                        <div className="btnWrp my-2 d-flex align-items-center justify-content-center gap-10 flex-wrap">
                          <div className="d-flex align-items-center gap-10 w-100">
                            <Button
                              onClick={handleMembership}
                              className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                            >
                              Update NTA Membership
                            </Button>
                          </div>
                        </div>
                      ) : practitionerDetail?.nta_status === "approve" &&
                        practitionerDetail?.nta_membership_type ===
                        "non_renewable" ? (
                        "Cancelling Member"
                      ) : practitionerDetail?.is_plan_active === 'yes' ? (
                        <div className="btnWrp my-2 d-flex align-items-center justify-content-center gap-10 flex-wrap">
                          <div className="d-flex align-items-center gap-10 w-100">
                            <Button
                              onClick={handleMembership}
                              className="d-flex align-items-center justify-content-center commonBtn btnSm w-100"
                            >
                              Update NTA Membership
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="bg-white px-4 py-3 card-box rounded">
                  <div className="top d-flex flex-wrap align-items-start  my-2 py-2 justify-content-between">
                    <h4 className="dashboard-head m-0">Personal Information</h4>
                    {/* <Link
                      to={`/admin/practitioner/edit/${editkey}`}
                      className="d-flex align-items-center justify-content-center commonBtn"
                    >
                      {" "}
                      Edit
                    </Link> */}
                  </div>
                  <div className="d-flex flex-wrap align-items-start gap-10">
                    <Button
                      onClick={handleEditUser}
                      className="d-flex align-items-center justify-content-center commonBtn"
                    >
                      Edit
                    </Button>
                    {practitionerDetail?.password_flag === 0 && (
                      <Button
                        onClick={resendPwdResetLink}
                        className="d-flex align-items-center justify-content-center commonBtn"
                      >
                        Resend Link
                      </Button>
                    )}
                    <Button
                      onClick={resetPassword}
                      className="d-flex align-items-center justify-content-center commonBtn"
                    >
                      Reset Password
                    </Button>
                  </div>
                  <div className="table-responsive">
                    <table class="table common-table">
                      <tbody>
                        {InputFields.map((field, idx) => (
                          <>
                            {field.view ? (
                              <tr className={``} key={idx}>
                                <td>
                                  <p className="m-0 fw-sbold text-dark">
                                    {field.label}
                                  </p>
                                </td>
                                <td>
                                  <p className="m-0">
                                    {[
                                      "email",
                                      "birth_date",
                                      "phone",
                                      "gender",
                                      "is_free_account",
                                      "free_account_expiry",
                                      "status",
                                      "password",
                                    ].includes(field.name)
                                      ? viewData[field.name] != null
                                        ? viewData[field.name]
                                        : "--"
                                      : "--"}
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 col-md-8 col-lg-8">
              <div className="py-2">
                <div className="bg-white px-4 py-3 card-box rounded">
                  <div className="top d-flex align-items-start  my-2 py-2 justify-content-between">
                    <h4 className="dashboard-head m-0">Subscription History</h4>
                  </div>
                  <div className="table-responsive">
                    <table class="table common-table">
                      <thead>
                        <th>#</th>
                        <th>Plan Name</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Plan Status</th>
                        <th>Created At</th>
                      </thead>
                      <tbody>
                        {practitionerHistory?.length > 0 && practitionerHistory?.map((data, index) => (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data?.purchased_package?.package_name}</td>
                              <td>${data?.purchased_package?.package_price}</td>
                              <td>{data?.status}</td>
                              <td>
                                {data?.plan_status === "trial"
                                  ? "trial"
                                  : data?.plan_status}
                              </td>
                              <td>
                                {data.created_at
                                  ? DATE_FORMAT(data?.created_at)
                                  : ""}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {practitionerHistory?.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
              <div className="py-2">
                <div className="bg-white px-4 py-3 card-box rounded" ref={clientsTableRef}>
                  <div className="top d-flex align-items-start  my-2 py-2 justify-content-between">
                    <h4 className="dashboard-head m-0">My Clients</h4>
                    {!practitionerDetail?.metadata?.preloadedSampleData && (
                      <Button
                        onClick={loadSampleData}
                      >
                        Load Sample Data
                      </Button>
                    )}
                  </div>
                  <div className="table-responsive">
                    <table class="table common-table">
                      <thead>
                        <th>#</th>
                        <th>Account Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Group</th>
                        <th>Type</th>
                        {/* <th>Fav</th>
                        <th>Tags</th> */}
                        <th>Created At</th>
                        <th>Last Updated</th>
                      </thead>

                      {myClintData?.length > 0 && myClintData?.map((data, index) => {
                        index = ((clintActivePage - 1) * PAGE_LIMIT) + index;
                        return (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {data?.uuid ? FORMAT_TEXT(data?.uuid) : "N/A"}{" "}
                              </td>
                              <td>
                                {data?.first_name
                                  ? FORMAT_TEXT(data?.first_name)
                                  : "N/A"}{" "}
                                {data?.last_name
                                  ? FORMAT_TEXT(data?.last_name)
                                  : "N/A"}
                              </td>
                              <td>
                                {data?.email ? FORMAT_TEXT(data?.email) : "N/A"}
                              </td>

                              <td>
                                {data?.is_archived == "yes"
                                  ? "Archived"
                                  : ["active", "inactive"].includes(data?.status)
                                    ? "Current"
                                    : "N/A"}
                              </td>
                              <td>
                                {data?.is_direct_account == "yes" &&
                                  data?.status == "active"
                                  ? "Direct Link"
                                  : data?.status == "active"
                                    ? "Active"
                                    : "Unlinked"}
                              </td>
                              <td>
                                {data?.created_at
                                  ? DATE_FORMAT(data?.created_at)
                                  : "N/A"}
                              </td>
                              <td>
                                {data?.updated_at
                                  ? DATE_FORMAT(data?.updated_at)
                                  : "N/A"}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </table>
                  </div>
                  {myClintData.length > 0 && (
                    <ReactPagination
                      activePage={clintActivePage}
                      itemsCountPerPage={itemsCountPerPageClint}
                      totalItemsCount={totalItemsCountClint}
                      handlePageChange={handleClintPageChange}
                    />
                  )}
                </div>
              </div>
              <div className="py-2">
                <div className="bg-white px-4 py-3 card-box rounded">
                  <div className="top d-flex align-items-start gap-10  my-2 py-2 justify-content-between border-bottom">
                    <div className="left">
                      <h4 className="dashboard-head m-0">Notes</h4>
                    </div>
                  </div>
                  <div className="CardBody py-2">
                    <Form ref={formRef} >
                      <Row>
                        <Col lg="12" className="my-2">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setCheckData(e.target.checked);
                            }}
                          />
                          <label style={{ padding: "5px" }}>
                            Contacted user
                          </label>
                        </Col>
                        {checkData ? (
                          <Col lg="12" className="my-2">
                            <input
                              type="date"
                              name="contact_at"
                              onChange={handleNotes}
                              className="form-control"
                            />
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col lg="12" className="my-2">
                          <textarea
                            name="notes_data"
                            id=""
                            rows="5"
                            placeholder="Add Project Name"
                            className="form-control"
                            onChange={handleNotes}
                          ></textarea>
                        </Col>
                        <Col lg="12" className="my-2">
                          <div className="FormbtnWrpper d-flex align-items-center justify-cosntent-between gap-10">
                            <Button
                              onClick={saveNotes}
                              className="d-flex align-items-center justify-content-center commonBtn btnSm"
                              disabled={checkData || checkNotes ? false : true}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div className="contentBody py-3 border-top mt-3">
                      <ul className="list-unstyled ps-0 mb-0 py-2 noLastBorder">
                        {practitionerNotes?.practitionerNotes.length > 0
                          ? practitionerNotes.practitionerNotes?.map((data) => (
                            <>

                              <li
                                className="py-3 my-3"
                              // style={{ background: "#fafafa" }}
                              >
                                <ul className="list-unstyled ps-0 mb-0 d-flex align-itmes-center gap-10">
                                  <li className="d-flex align-items-center px-2">
                                    <span className="icn me-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                      >
                                        <path
                                          d="M15.8333 6.33366L9.49992 10.292L3.16659 6.33366V4.75033L9.49992 8.70866L15.8333 4.75033M15.8333 3.16699H3.16659C2.28784 3.16699 1.58325 3.87158 1.58325 4.75033V14.2503C1.58325 14.6703 1.75007 15.073 2.047 15.3699C2.34393 15.6668 2.74666 15.8337 3.16659 15.8337H15.8333C16.2532 15.8337 16.6559 15.6668 16.9528 15.3699C17.2498 15.073 17.4166 14.6703 17.4166 14.2503V4.75033C17.4166 4.3304 17.2498 3.92767 16.9528 3.63074C16.6559 3.33381 16.2532 3.16699 15.8333 3.16699Z"
                                          fill="#979797"
                                        />
                                      </svg>
                                    </span>
                                    <p className="m-0 fw-sbold">
                                      {data?.email}
                                    </p>
                                  </li>
                                  <li className="d-flex align-items-center px-2">
                                    <span className="icn me-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M0.770752 7.49967C0.770752 5.71499 1.47972 4.0034 2.74168 2.74144C4.00364 1.47947 5.71523 0.770508 7.49992 0.770508C9.2846 0.770508 10.9962 1.47947 12.2582 2.74144C13.5201 4.0034 14.2291 5.71499 14.2291 7.49967C14.2291 9.28436 13.5201 10.996 12.2582 12.2579C10.9962 13.5199 9.2846 14.2288 7.49992 14.2288C5.71523 14.2288 4.00364 13.5199 2.74168 12.2579C1.47972 10.996 0.770752 9.28436 0.770752 7.49967ZM8.09367 3.54134C8.09367 3.38387 8.03111 3.23285 7.91976 3.1215C7.80841 3.01015 7.65739 2.94759 7.49992 2.94759C7.34245 2.94759 7.19142 3.01015 7.08007 3.1215C6.96872 3.23285 6.90617 3.38387 6.90617 3.54134V7.49967C6.90613 7.60034 6.93168 7.69937 6.98043 7.78744C7.02917 7.87552 7.09951 7.94975 7.18484 8.00317L9.55984 9.48755C9.69337 9.57111 9.85464 9.59821 10.0081 9.56288C10.0842 9.54538 10.156 9.51308 10.2195 9.46783C10.283 9.42258 10.337 9.36525 10.3784 9.29913C10.4198 9.23301 10.4477 9.15939 10.4607 9.08247C10.4736 9.00555 10.4712 8.92683 10.4537 8.85082C10.4362 8.77481 10.404 8.70298 10.3587 8.63945C10.3134 8.57592 10.2561 8.52193 10.19 8.48055L8.09367 7.17034V3.54134Z"
                                          fill="#979797"
                                        />
                                      </svg>
                                    </span>
                                    <p className="m-0 fw-sbold">
                                      {formatTimestamp(data?.created_at)}
                                    </p>
                                  </li>
                                </ul>
                                {data?.notes_data?.before && data?.notes_data.before ?
                                  <>
                                    <Row>
                                      <Col lg="12" className="my-2">
                                        <div
                                          className=" rounded pt-2"
                                        // style={{ background: "#fafafa" }}
                                        >
                                          <div className="top px-3 d-flex align-items-start  my-2 py-2 justify-content-between">
                                            <h4 className="dashboard-head m-0">
                                              Updates
                                            </h4>
                                          </div>
                                          <div className="table-responsive border-0">

                                            <table className="table m-0 border-0">
                                              <thead>
                                                <tr>
                                                  <th className="py-3 px-3">
                                                    Attributes
                                                  </th>
                                                  <th className="py-3 px-3">
                                                    Before
                                                  </th>
                                                  <th className="py-3 px-3">
                                                    After
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className="p-0 border-0">
                                                    {data?.notes_data?.before ? (
                                                      <div className="m-0 fw-sbold text-dark">
                                                        {Object.entries(
                                                          data?.notes_data?.before
                                                        ).map(
                                                          ([key, value]) =>
                                                          (
                                                            <>
                                                              <div
                                                                className="py-3 px-3 border-bottom"
                                                                key={key}
                                                              >
                                                                <span>
                                                                  {
                                                                    key
                                                                      .replace(
                                                                        /nta/g,
                                                                        ""
                                                                      ) // Remove "nta"
                                                                      .replace(
                                                                        /_/g,
                                                                        (
                                                                          match,
                                                                          index
                                                                        ) =>
                                                                          index ===
                                                                            key.indexOf(
                                                                              "_"
                                                                            )
                                                                            ? " "
                                                                            : ""
                                                                      ) // Replace the second underscore with a space
                                                                      .replace(
                                                                        /^[a-z]/,
                                                                        (
                                                                          match
                                                                        ) =>
                                                                          match.toUpperCase()
                                                                      ) // Capitalize the first letter after "nta"
                                                                  }
                                                                </span>
                                                              </div>{" "}
                                                            </>
                                                          )
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td className="p-0 border-0">
                                                    {data?.notes_data?.before ? (
                                                      <div className="m-0 fw-sbold text-dark">
                                                        {Object.entries(
                                                          data?.notes_data?.before
                                                        ).map(

                                                          ([key, value]) =>

                                                          (
                                                            <div
                                                              className="py-3 px-3 border-bottom"
                                                              key={key}
                                                            >
                                                              <span className="m-0 d-inline-block">
                                                                {(value && moment(value).isValid()) ? DATE_FORMAT(value) : (value || "N/A")}
                                                              </span>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td className="p-0 border-0">
                                                    {data?.notes_data?.after ? (
                                                      <div className="m-0 fw-sbold text-dark">
                                                        {" "}
                                                        {Object.entries(
                                                          data?.notes_data?.after
                                                        ).map(
                                                          ([key, value]) =>
                                                          // Check if the value is not null or undefined
                                                          (
                                                            <>
                                                              <div
                                                                className="py-3 px-3 border-bottom"
                                                                key={key}
                                                              >
                                                                <span>
                                                                  {(value && moment(value).isValid()) ? DATE_FORMAT(value) : (value || "N/A")}
                                                                </span>
                                                              </div>{" "}
                                                            </>
                                                          )
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>


                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="d-flex align-items-center flex-wrap gap-10 d-none">
                                      {data?.notes_data?.before ? (
                                        <div className="m-0 fw-sbold text-dark">
                                          <p className="m-0">
                                            {Object.entries(
                                              data?.notes_data?.before
                                            ).map(
                                              ([key, value]) =>
                                                // Check if the value is not null or undefined
                                                value !== null &&
                                                value !== undefined && (
                                                  <span key={key}>
                                                    <span className="m-0 d-inline-block">
                                                      {
                                                        key
                                                          .replace(/nta/g, "") // Remove "nta"
                                                          .replace(
                                                            /_/g,
                                                            (match, index) =>
                                                              index ===
                                                                key.indexOf("_")
                                                                ? " "
                                                                : ""
                                                          ) // Replace the second underscore with a space
                                                          .replace(
                                                            /^[a-z]/,
                                                            (match) =>
                                                              match.toUpperCase()
                                                          ) // Capitalize the first letter after "nta"
                                                      }
                                                      : {value}
                                                    </span>
                                                  </span>
                                                )
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {data?.notes_data?.after ? (
                                        <div className="m-0 fw-sbold text-dark">
                                          {" "}
                                          <p className="m-0">
                                            {"=>"}
                                            {Object.entries(
                                              data?.notes_data?.after
                                            ).map(
                                              ([key, value]) =>
                                                // Check if the value is not null or undefined
                                                value !== null &&
                                                value !== undefined && (
                                                  <>
                                                    <span key={key}>
                                                      <span>
                                                        {
                                                          key
                                                            .replace(/nta/g, "") // Remove "nta"
                                                            .replace(
                                                              /_/g,
                                                              (match, index) =>
                                                                index ===
                                                                  key.indexOf("_")
                                                                  ? " "
                                                                  : ""
                                                            ) // Replace the second underscore with a space
                                                            .replace(
                                                              /^[a-z]/,
                                                              (match) =>
                                                                match.toUpperCase()
                                                            ) // Capitalize the first letter after "nta"
                                                        }
                                                        :{value}
                                                      </span>
                                                    </span>{" "}
                                                  </>
                                                )
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                  :
                                  <>
                                    <Row>
                                      <Col lg="12" className="my-2">
                                        {data?.notes_data?.contact_at ? <>
                                          <h6> Contacted At :-</h6>
                                          {data?.notes_data?.contact_at}
                                        </> : ""}
                                        <div className="top px-3 d-flex align-items-start  my-2 py-2 justify-content-between">
                                          <p>
                                            {data?.notes_data?.message}
                                          </p>

                                        </div>

                                      </Col>
                                    </Row>
                                  </>
                                }
                              </li>
                            </>
                          ))
                          : "Data Not Available"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    practitionerNotes: {
      practitionerNotes: state.Practitioner?.practitionersNotes ? state.Practitioner.practitionersNotes : [],
      totalItemsCountNotes: state.Practitioner.totalItemsCountNotes,
      itemsCountPerPageNotes: state.Practitioner.itemsCountPerPageNotes,
    },
    pagination: {
      itemsCountPerPage: state.Practitioner.itemsCountPerPage,
      totalItemsCount: state.Practitioner.totalItemsCount,
      itemsCountPerPageClint: state.Practitioner.itemsCountPerPageClint,
      totalItemsCountClint: state.Practitioner.totalItemsCountClint,
    },
    myClintData: state.Practitioner?.practitionersMyClint ? state.Practitioner?.practitionersMyClint : [],
    practitionerHistory: state.Practitioner?.practitionersHistory ? state.Practitioner?.practitionersHistory : [],
    practitionerDetail: state.Practitioner.practitioner,
    loader: state.Practitioner.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

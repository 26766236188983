let mainPath = "/admin";
//AUTH
export const login = "/admin/login";
export const forgot_password = "/admin/forgot_password";
export const change_password = "/admin/change_password";
export const reset_password = "/admin/reset_password/:token";
export const dashboard = `${mainPath}/dashboard`;
//Profile
export const profile = `${mainPath}/profile`;

//Practitioners
export const my_practitioners = `${mainPath}/practitioners/list`;

//Subscriptions
export const subscriptions = `${mainPath}/subscriptions/list`;
export const subscription_add = `${mainPath}/subscriptions/add`;
export const subscriptionedit = `${mainPath}/subscriptions/edit`;
export const subscription_edit = `${mainPath}/subscriptions/edit/:id`;
export const subscriptionview = `${mainPath}/subscriptions/view`;
export const subscription_view = `${mainPath}/subscriptions/view/:id`;

//Categories
export const category = `${mainPath}/category/list`;
export const category_add = `${mainPath}/category/add`;
export const categoryedit = `${mainPath}/category/edit`;
export const category_edit = `${mainPath}/category/edit/:id`;
export const categoryview = `${mainPath}/category/view`;
export const category_view = `${mainPath}/category/view/:id`;

//Subcategories
export const subcategory = `${mainPath}/subcategory/list`;
export const subcategory_add = `${mainPath}/subcategory/add`;
export const subcategoryedit = `${mainPath}/subcategory/edit`;
export const subcategory_edit = `${mainPath}/subcategory/edit/:id`;
export const subcategoryview = `${mainPath}/subcategory/view`;
export const subcategory_view = `${mainPath}/subcategory/view/:id`;

//Resources
export const resource = `${mainPath}/resource/list`;
export const resource_add = `${mainPath}/resource/add`;
export const resourceedit = `${mainPath}/resource/edit`;
export const resource_edit = `${mainPath}/resource/edit/:id`;
export const resourceview = `${mainPath}/resource/view`;
export const resource_view = `${mainPath}/resource/view/:id`;

//Content pages
export const content_pages = `${mainPath}/pages/list`;
export const content_add = `${mainPath}/pages/add`;
export const contentedit = `${mainPath}/pages/edit`;
export const content_edit = `${mainPath}/pages/edit/:id`;

//Faq
export const faq = `${mainPath}/faq/list`;
export const faq_add = `${mainPath}/faq/add`;
export const faq_edit = `${mainPath}/faq/edit/:id`;
export const faqedit = `${mainPath}/faq/edit`;
export const faqview = `${mainPath}/faq/view`;
export const faq_view = `${mainPath}/faq/view/:id`;

//Practitioner
export const practitioner = `${mainPath}/practitioner/list`;
export const practitioner_add = `${mainPath}/practitioner/add`;
export const practitioner_edit = `${mainPath}/practitioner/edit/:id`;
export const practitioneredit = `${mainPath}/practitioner/edit`;
export const practitionerview = `${mainPath}/practitioner/view`;
export const practitioner_view = `${mainPath}/practitioner/view/:id`;
// export const practitioner_delete = `${mainPath}/practitioner/delete/:id`;



// clients
export const manageClients = `${mainPath}/client/list`;
export const clientview = `${mainPath}/client/view`;
export const client_view = `${mainPath}/client/view/:id`;

//system access
export const system_access = `${mainPath}/system_access/list`;
export const system_access_add = `${mainPath}/system_access/add`;
export const system_access_edit = `${mainPath}/system_access/edit/:id`;
export const system_accessedit = `${mainPath}/system_access/edit`;
export const system_accessview = `${mainPath}/system_access/view`;
export const system_access_view = `${mainPath}/system_access/view/:id`;

//Earning Management
export const earning = `${mainPath}/earning/list`;

//Subscription History
export const subscription_history = `${mainPath}/subscription_history/list`;

//pages
export const content = `${mainPath}/content/list`;

// Configuration
export const configuration = `${mainPath}/configuration/settings`;
// Configuration Instance permissions
export const instance_permissions = `${mainPath}/configuration/permissions`;

//notification
export const notification = `${mainPath}/notification/list`;
export const notification_add = `${mainPath}/notification/add`;
//client catgeory form
export const client_category_form = `${mainPath}/client_category_form/list`;

//email Template
export const email_template = `${mainPath}/email-template`;
export const email_template__edit = `${mainPath}/email-template/edit`;
export const email_template_edit = `${mainPath}/email-template/edit/:id`;

// Help pages
export const help_pages = `${mainPath}/help-page`;
export const help_pages_add = `${mainPath}/help-page/add`;
export const help_pages_edit = `${mainPath}/help-page/edit`;
export const help_pages_edit_ = `${mainPath}/help-page/edit/:id`;
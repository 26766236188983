import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import {DUMMY_SAMPLET_STUDENET_PRAC} from 'helpers/common'
import { CSVLink } from "react-csv";
import { toast } from 'react-toastify';

import {
    getPractitionerList,
  } from "store/services/practitionerService";
export default function ImportModal({dispatch, openModalImport, setOpenModalImport, handleSubmitData,setCsvField,csvField, typeCsv_, setTypeCsv_, sending}) {
    const handleCancel = () => setOpenModalImport(!openModalImport);
    const [csvDownload, setCsvDownload] = useState(null)
    const [key, setKey] = useState(0);
    const [preloadData, setPreloadData] = useState(false);

    const importSmapleStudentPrac = async () => {
        try {
            let practitionerList;
            if(typeCsv_ === 'practitioner_update'){
                let res_ = await dispatch(getPractitionerList());
                if(res_?.body?.data){
                    practitionerList = res_?.body?.data
                }
            }
            let response =  await DUMMY_SAMPLET_STUDENET_PRAC(typeCsv_, practitionerList);
            if (response && !csvDownload) {
                setCsvDownload(response)
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    useEffect(() => {
        if (typeCsv_ !== null) {
            setTypeCsv_(typeCsv_);
        }
    }, [typeCsv_]);
      
    const handleChangeSelect = (evt) => {
        let fileType = evt.target.value;
        setTypeCsv_(fileType);
        setKey(prevKey => prevKey + 1);
        if(fileType !== "student") {
            setPreloadData(false);
        }
    };
    const handleChange = useCallback((evt)  => {
        if(evt.target.files){
            const file = evt.target.files[0]; // Assuming it's a single file upload
            const allowedFileType = "text/csv"; // Set the allowed file type as CSV
        
            if (file && file.type === allowedFileType) {
                const reader = new FileReader();
                reader.onload = function(event) {
                    const base64Data = event.target.result;
                    // Assuming 'name' was meant to be a key within csvField state
                    setCsvField(prevState => ({
                        ...prevState,
                        // Assuming 'name' is replaced with the actual key name within csvField
                        file: base64Data,
                    }));
                };
                reader.readAsDataURL(file);
            } else {
                // Handle file type not allowed error or feedback
                console.log("Please select a CSV file");
                // You can also set an error state or provide feedback to the user
            }
        }
    }, [setCsvField]); // Ensure to include dependencies as needed in the dependency array

    const handleSubmit = (e) => {
        try {
            const callback = () => {
                setPreloadData(false);
            }
            const data = {
                preloadData
            }
            handleSubmitData(e, data, callback);
        } catch(err) {
            console.log("Error occurred in handle submit", err);
        }
    }
    

    return (
        <>
        <Form>
            <Modal
                show={openModalImport}
                onHide={handleCancel}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Import</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                            Select Type for import file
                        </label>
                        <select
                            name="import_type"
                            className="form-select"
                            onChange={(e) => handleChangeSelect(e)}
                            required
                        >
                            <option value="">None</option>
                            <option value="student">Student</option>
                            <option value="practitioner">Practitioner</option>
                            <option value="practitioner_update" disabled>Update/Import Practitioner (WIP)</option>
                        </select>
                       
                    </Col>
                   <br/>
                   {typeCsv_ &&
                    <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                        {typeCsv_==='student' ? "Import Student" : typeCsv_ === 'practitioner' ? "Import Practitioner" : "Update/Import"}
                        </label>
                        <input   key={key} type="file" className="form-control" required accept=".csv" name="file" onChange={(e) => handleChange(e)} />
                        <span className="download_sample" onClick={() => importSmapleStudentPrac()}><p>Download sample here &nbsp;
                        {(csvDownload && typeCsv_ ) && (
                        <CSVLink
                          onClick={() => setCsvDownload(null)}
                          data={csvDownload }
                          filename={typeCsv_==='student' ? "Student.csv" : typeCsv_ === 'practitioner' ? "Practitioner.csv" : "Practitioner_.csv"}
                          className="btn btn-primary csv_dwnld_sample"
                        >
                         <i className="fa fa-download" aria-hidden="true"></i>

                        </CSVLink>
                        )}
                        </p>
                        </span>
                        {typeCsv_==='student' && (
                            <div class="ps-4">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    checked={preloadData}
                                    onChange={(e) => {
                                        setPreloadData(e.target.checked);
                                    }}
                                />
                                <label title="" class="form-label m-0 fw-sbold">Preload sample data</label>
                            </div>
                        )}
                    </Col>
                    }
                    {/* <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                           Or
                        </label>
                    </Col>
                    <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                            Import for practitioner
                        </label>
                        <input type="file" className="form-control" accept=".csv" name="prac_file" onChange={handleChange("prac_file", "practitioner")}/>
                        <span className="download_sample" onClick={() => importSmapleStudentPrac('practitioner')}><p>Download sample here &nbsp;
                            {(csvDownload && typeCsv_=== 'practitioner') && (
                            <CSVLink
                            onClick={() => setCsvDownload(null)}
                            data={csvDownload }
                            filename="Practitioner.csv"
                            className="btn btn-primary csv_dwnld_sample"
                            >
                            <i className="fa fa-download" aria-hidden="true"></i>

                            </CSVLink>    
                            )}
                        </p></span>
                    </Col>
                    <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 fw-sbold">
                            Update/Import
                        </label>
                        <input type="file" className="form-control" accept=".csv" name="prac_file" onChange={handleChange("prac_file", "practitioner_update")}/>
                        <span className="download_sample" onClick={() => importSmapleStudentPrac('practitioner_update')}><p>Download sample here &nbsp;
                            {(csvDownload && typeCsv_=== 'practitioner_update') && (
                            <CSVLink
                            onClick={() => setCsvDownload(null)}
                            data={csvDownload }
                            filename="Practitioner_.csv"
                            className="btn btn-primary csv_dwnld_sample"
                            >
                            <i className="fa fa-download" aria-hidden="true"></i>

                            </CSVLink>    
                            )}
                        </p></span>
                    </Col> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={handleCancel}
                    >
                        Close
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit} disabled={sending ?  true : false}>
                       {sending ? 'Wait...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
        </>
    );
}

